import React from 'react'
import { Container, Divider, Heading } from '@chakra-ui/react'
import HeroHeaderNav from './HeroHeaderNav'
import HorizontalGallery from './HorizontalGallery'
import ContentCTA from './ContentCTA'
import { useEventContext } from '../../../contexts/EventProvider'
import { cartoonize } from './data/cartoonize.data'
import { celebrity } from './data/celebrity.data'
import { photosLiving } from './data/photosLiving.data'
import { photofunia } from './data/photofunia.data'
import { libraries } from './data/libraries.data'
import { importable } from './data/importable.data'
import { custom } from './data/custom.data'
import { collectibles } from './data/collectibles.data'

const CreatorView = () => {
	const { event, isOwner } = useEventContext()

	return (
		<>
			<HeroHeaderNav
				path="/assets/images/marketplace/hero/vidday-video-gift-add-ons-hero"
				key={'creator'}
				extension="jpg">
				<Heading
					w={['220px', '600px']}
					textAlign={['center', 'left']}
					m={['-2rem auto auto', 'auto auto auto 0']}
					as="h1"
					fontSize={['2rem', '3rem']}
					variant="hero"
					color="white">
					Personalized content for your video!
				</Heading>
			</HeroHeaderNav>

			<Container
				variant="marketplace"
				mt={
					isOwner || !event
						? ['calc(144px + 2rem)', 'calc(144px + 2rem)']
						: ['calc(280px + 2rem)', 'calc(224px + 2rem)']
				}>
				<HorizontalGallery heading="Book a celebrity shoutout" collection={celebrity} />

				<HorizontalGallery heading="Get custom-made content" collection={custom} />

				<HorizontalGallery tag="FREE" heading="Get creative with photos" collection={photofunia} />

				<HorizontalGallery heading="Become a cartoon character" collection={cartoonize} />

				<HorizontalGallery heading="Bring photos to life" collection={photosLiving} />

				{/* <HorizontalGallery heading="Get collectibles &amp; merchandise" collection={collectibles} /> */}

				<HorizontalGallery tag="FREE" heading="Explore media libraries" collection={libraries} />

				{event && <HorizontalGallery tag="FREE" heading="Import media" collection={importable} />}

				<Divider variant="dashed" mb="2rem" />

				<ContentCTA />
			</Container>
		</>
	)
}

export default CreatorView
