export const photosLiving = [
	{
		path: '/assets/images/marketplace/photos-living/vidday-video-gift-add-ons-graphic-photos-to-life-reface',
		logo: '/assets/images/marketplace/photos-living/logos/vidday-video-gift-add-ons-logo-icon-reface',
		title: 'Reface',
		text: 'Swap and animate faces',
		isExternal: true,
		href: 'https://reface.onelink.me/lVlG/gzcnh9r1',
	},
	{
		path: '/assets/images/marketplace/photos-living/vidday-video-gift-add-ons-graphic-photos-to-life-my-talking-pet',
		logo: '/assets/images/marketplace/photos-living/logos/vidday-video-gift-add-ons-logo-icon-my-talking-pet',
		title: 'My Talking Pet',
		text: 'Bring photos of pets to life',
		isExternal: true,
		href: 'https://apps.apple.com/us/app/my-talking-pet/id1427290424',
	},
	{
		path: '/assets/images/marketplace/photos-living/vidday-video-gift-add-ons-graphic-photos-to-life-hotpot',
		logo: '/assets/images/marketplace/photos-living/logos/vidday-video-gift-add-ons-logo-icon-hotpot',
		title: 'Hotpot',
		text: 'Colorize and restore photos',
		isExternal: true,
		href: 'https://hotpot.ai/colorize-picture',
	},
	{
		path: '/assets/images/marketplace/photos-living/vidday-video-gift-add-ons-graphic-photos-to-life-face-swap-live',
		logo: '/assets/images/marketplace/photos-living/logos/vidday-video-gift-add-ons-logo-icon-face-swap-live',
		title: 'Face Swap Live',
		text: 'Swap faces with photos',
		isExternal: true,
		href: 'http://faceswaplive.com/',
	},
]
