export const custom = [
	{
		path: '/assets/images/marketplace/custom/vidday-video-gift-add-ons-graphic-custom-content-songfinch',
		logo: '/assets/images/marketplace/custom/logos/vidday-video-gift-add-ons-logo-icon-songfinch',
		title: 'Hotpot',
		title: 'Songfinch',
		text: 'Get custom songs',
		isExternal: true,
		href: 'https://songfinch.pxf.io/LXY04a',
	},
	{
		path: '/assets/images/marketplace/custom/vidday-video-gift-add-ons-graphic-custom-content-newscast-video',
		title: 'Newscast Video',
		text: 'Request a custom newscast video',
		isExternal: true,
		href: 'https://go.fiverr.com/visit/?bta=515236&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fbookreviewstew%2Fproduce-a-30-second-newscast-style-birthday-or-special-occasion-ecard',
	},
	{
		path: '/assets/images/marketplace/custom/vidday-video-gift-add-ons-graphic-custom-content-breaking-news-video',
		title: 'Breaking News Video',
		text: 'Request a custom breaking news video',
		isExternal: true,
		href: 'https://www.fiverr.com/justjosiejo/be-your-breaking-news-video-spokesperson?utm_source=515236&utm_medium=cx_affiliate&utm_campaign=&afp=&cxd_token=515236_27852263&show_join=true',
	},
	{
		path: '/assets/images/marketplace/custom/vidday-video-gift-add-ons-graphic-custom-content-ukelele-greeting',
		title: 'Ukelele Greeting Video',
		text: 'Request a custom musical tribute',
		isExternal: true,
		href: 'https://go.fiverr.com/visit/?bta=515236&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fguitarlancer%2Fplay-personal-birthday-song-for-special-someone-on-video',
	},
	{
		path: '/assets/images/marketplace/custom/vidday-video-gift-add-ons-graphic-custom-content-opera-greeting',
		title: 'Opera Greeting Video',
		text: 'Request a custom musical tribute',
		isExternal: true,
		href: 'https://go.fiverr.com/visit/?bta=515236&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fhorndavb%2Fsing-opera-happy-birthday',
	},
	{
		path: '/assets/images/marketplace/custom/vidday-video-gift-add-ons-graphic-custom-content-cute-cat-video',
		title: 'Cute Cat Video',
		text: 'Request a custom birthday video',
		isExternal: true,
		href: 'https://go.fiverr.com/visit/?bta=515236&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fbasketballskill%2Fmake-a-funny-happy-birthday-video-with-a-cute-cat',
	},
	{
		path: '/assets/images/marketplace/custom/vidday-video-gift-add-ons-graphic-custom-content-cute-cake-video',
		title: 'Photo Cake Video',
		text: 'Request a custom birthday video',
		isExternal: true,
		href: 'https://go.fiverr.com/visit/?bta=515236&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fcreativeagents%2Fmake-a-valentine-day-video-for-your-love',
	},
]
