import React from 'react'
import { Box, Heading, HStack, Tag, TagLabel, TagRightIcon, Text, VStack } from '@chakra-ui/react'
import Carousel from '../../../molecules/Carousel'
import { AutoAwesomeRounded } from '../../../atoms/Icon'

const HorizontalGallery = ({ tag, heading, subHeading, collection }) => {
	return (
		<Box mb="2rem" w="full">
			<HStack spacing={4}>
				<VStack spacing="0" alignItems="start" pl={['1rem']}>
					<Heading as="h3" size="md">
						{heading}
					</Heading>
					<Text as="h6" size="sm">
						{subHeading}
					</Text>
				</VStack>
				{tag && (
					<Tag size={'md'} color="link" colorScheme="blue" variant="outline">
						<TagLabel>{tag}</TagLabel>
						<TagRightIcon minH="1rem" minW="1rem" as={AutoAwesomeRounded} />
					</Tag>
				)}
			</HStack>

			<Box maxH="400px" w="full">
				<Carousel
					collection={collection}
					_before={
						collection.length > 2 && {
							pointerEvents: 'none',
							content: '""',
							width: '.5rem',
							height: '100%',
							position: 'absolute',
							bottom: '0',
							zIndex: '3',
							left: '0',
							background: 'linear-gradient( -90deg, rgba(242,245,246,0) 0%, rgba(242,245,246,1) 100% )',
						}
					}
					_after={
						collection.length > 2 && {
							pointerEvents: 'none',
							content: '""',
							width: '1rem',
							height: '100%',
							position: 'absolute',
							bottom: '0',
							zIndex: '0',
							right: '0',
							background: 'linear-gradient( 90deg, rgba(242,245,246,0) 0%, rgba(242,245,246,1) 75% )',
						}
					}
				/>
			</Box>
		</Box>
	)
}
export default HorizontalGallery
