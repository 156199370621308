export const cartoonize = [
	{
		path: '/assets/images/marketplace/cartoonize/vidday-video-gift-add-ons-graphic-cartoon-the-simpsons',
		title: 'The Simpsons',
		text: 'Turn photos into custom portraits',
		isExternal: true,
		href: 'https://shareasale.com/r.cfm?b=1738519&u=3124742&m=108314&urllink=&afftrack=',
	},
	{
		path: '/assets/images/marketplace/cartoonize/vidday-video-gift-add-ons-graphic-cartoon-family-guy',
		title: 'Family Guy',
		text: 'Turn photos into custom portraits',
		isExternal: true,
		href: 'https://itoonify.com/products/family-guy?ref=VidDay',
	},
	{
		path: '/assets/images/marketplace/cartoonize/vidday-video-gift-add-ons-graphic-cartoon-star-wars',
		title: 'Star Wars',
		text: 'Turn photos into custom portraits',
		isExternal: true,
		href: 'https://itoonify.com/products/star-wars?ref=VidDay',
	},
	{
		path: '/assets/images/marketplace/cartoonize/vidday-video-gift-add-ons-graphic-cartoon-south-park',
		title: 'South Park',
		text: 'Turn photos into custom portraits',
		isExternal: true,
		href: 'https://itoonify.com/products/line-art?ref=VidDay',
	},
	{
		path: '/assets/images/marketplace/cartoonize/vidday-video-gift-add-ons-graphic-cartoon-bobs-burgers',
		title: "Bob's Burgers",
		text: 'Turn photos into custom portraits',
		isExternal: true,
		href: 'https://itoonify.com/products/bobs-burgers?ref=VidDay',
	},
	{
		path: '/assets/images/marketplace/cartoonize/vidday-video-gift-add-ons-graphic-cartoon-rick-and-morty',
		title: 'Rick and Morty',
		text: 'Turn photos into custom portraits',
		isExternal: true,
		href: 'https://itoonify.com/products/rick-and-morty?ref=VidDay',
	},
	{
		path: '/assets/images/marketplace/cartoonize/vidday-video-gift-add-ons-graphic-cartoon-pokemon',
		title: 'Pokémon',
		text: 'Turn photos into custom portraits',
		isExternal: true,
		href: 'https://itoonify.com/products/pokemon?ref=VidDay',
	},
	{
		path: '/assets/images/marketplace/cartoonize/vidday-video-gift-add-ons-graphic-cartoon-star-trek',
		title: 'Star Trek',
		text: 'Turn photos into custom portraits',
		isExternal: true,
		href: 'https://itoonify.com/products/star-trek?ref=VidDay',
	},
]
