export const collectibles = [
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-marvel',
		isExternal: true,
		href: 'https://www.dpbolvw.net/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fmarvel',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-star-wars',
		isExternal: true,
		href: 'https://www.jdoqocy.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fstar-wars',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-harry-potter',
		isExternal: true,
		href: 'https://www.anrdoezrs.net/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fharry-potter',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-the-office',
		isExternal: true,
		href: 'https://www.kqzyfj.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fthe-office',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-disney',
		isExternal: true,
		href: 'https://www.tkqlhce.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fdisney-toys-collectibles',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-game-of-thrones',
		isExternal: true,
		href: 'https://www.tkqlhce.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fgame-of-thrones',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-rick-and-morty',
		isExternal: true,
		href: 'https://www.kqzyfj.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Frick-morty',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-friends',
		isExternal: true,
		href: 'https://www.jdoqocy.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Ffriends',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-minecraft',
		isExternal: true,
		href: 'https://www.kqzyfj.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fminecraft',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-dc-comics',
		isExternal: true,
		href: 'https://www.jdoqocy.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fdc-comics',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-doctor-who',
		isExternal: true,
		href: 'https://www.dpbolvw.net/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fdoctor-who',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-bob-ross',
		isExternal: true,
		href: 'https://www.tkqlhce.com/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fbob-ross',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-the-golden-girls',
		isExternal: true,
		href: 'https://www.anrdoezrs.net/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fthe-golden-girls',
		variant: 'tiny',
	},
	{
		path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-geeki-tikis',
		isExternal: true,
		href: 'https://www.dpbolvw.net/click-100182648-15198728?url=https%3A%2F%2Fwww.toynk.com%2Fcollections%2Fgeeki-tikis',
		variant: 'tiny',
	},
	// {
	// 	path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-squishmallows',
	// 	isExternal: true,
	// 	href: '',
	// 	variant: 'tiny',
	// },
	// {
	// 	path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-looksee-box',
	// 	isExternal: true,
	// 	href: '',
	// 	variant: 'tiny',
	// },
	// {
	// 	path: '/assets/images/marketplace/collectibles/vidday-gift-shop-graphic-collectibles-the-mandalorian',
	// 	isExternal: true,
	// 	href: '',
	// 	variant: 'tiny',
	// },
]
