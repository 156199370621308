export const celebrity = [
	{
		path: '/assets/images/marketplace/celebrity/vidday-video-gift-add-ons-graphic-celebrity-shoutout-music',
		title: 'Music',
		text: '',
		isExternal: true,
		href: 'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref=marketplace&ued=https%3A%2F%2Fmemmo.me%2Fprofiles%2Fmusician-tag',
		variant: 'small',
	},
	{
		path: '/assets/images/marketplace/celebrity/vidday-video-gift-add-ons-graphic-celebrity-shoutout-sports',
		title: 'Sports',
		text: '',
		isExternal: true,
		href: 'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref=marketplace&ued=https%3A%2F%2Fmemmo.me%2Fprofiles%2Fsports-tag',
		variant: 'small',
	},
	{
		path: '/assets/images/marketplace/celebrity/vidday-video-gift-add-ons-graphic-celebrity-shoutout-comedians',
		title: 'Comedians',
		text: '',
		isExternal: true,
		href: 'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref=marketplace&ued=https%3A%2F%2Fmemmo.me%2Fprofiles%2Fcomedian-tag',
		variant: 'small',
	},
	{
		path: '/assets/images/marketplace/celebrity/vidday-video-gift-add-ons-graphic-celebrity-shoutout-actors',
		title: 'Actors',
		text: '',
		isExternal: true,
		href: 'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref=marketplace&ued=https%3A%2F%2Fmemmo.me%2Fprofiles%2Factor-tag',
		variant: 'small',
	},
	{
		path: '/assets/images/marketplace/celebrity/vidday-video-gift-add-ons-graphic-celebrity-shoutout-for-kids',
		title: 'For Kids',
		text: '',
		isExternal: true,
		href: 'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref=marketplace&ued=https%3A%2F%2Fmemmo.me%2Fprofiles%2Fkids-tag',
		variant: 'small',
	},
	{
		path: '/assets/images/marketplace/celebrity/vidday-video-gift-add-ons-graphic-celebrity-shoutout-impersonators',
		title: 'Impersonators',
		text: '',
		isExternal: true,
		href: 'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref=marketplace&ued=https%3A%2F%2Fmemmo.me%2Fprofiles%2Fimpersonator-tag',
		variant: 'small',
	},
	{
		path: '/assets/images/marketplace/celebrity/vidday-video-gift-add-ons-graphic-celebrity-shoutout-nfl',
		title: 'NFL',
		text: '',
		isExternal: true,
		href: 'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref=marketplace&ued=https%3A%2F%2Fmemmo.me%2Fprofiles%2Fnfl-tag',
		variant: 'small',
	},
	{
		path: '/assets/images/marketplace/celebrity/vidday-video-gift-add-ons-graphic-celebrity-shoutout-reality',
		title: 'Reality',
		text: '',
		isExternal: true,
		href: 'https://www.awin1.com/cread.php?awinmid=28411&awinaffid=1093747&clickref=marketplace&ued=https%3A%2F%2Fmemmo.me%2Fprofiles%2Freality-tag',
		variant: 'small',
	},
]
