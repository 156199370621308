export const gifts = [
	{
		path: '/assets/images/marketplace/gifts/vidday-gift-shop-graphic-unique-gifts-personalized-gifts',
		logo: '/assets/images/marketplace/gifts/logos/vidday-gift-shop-logo-icon-uncommon-goods',
		title: 'Personalized Gifts',
		text: 'Get gifts made just for them',
		isExternal: true,
		href: 'http://uncommongoods.sjv.io/vnJq6O',
	},
	// {
	// 	path: '/assets/images/marketplace/gifts/vidday-gift-shop-graphic-unique-gifts-custom-photo-gifts',
	// 	logo: '/assets/images/marketplace/gifts/logos/vidday-gift-shop-logo-icon-collage',
	// 	title: 'Custom Photo Gifts',
	// 	text: 'Personalize gifts with photos',
	// 	isExternal: true,
	// 	href: 'https://collage.sjv.io/QOM3dM',
	// },
	{
		path: '/assets/images/marketplace/gifts/vidday-gift-shop-graphic-unique-gifts-custom-treats',
		logo: '/assets/images/marketplace/gifts/logos/vidday-gift-shop-logo-icon-m&ms',
		title: 'Custom Treats',
		text: 'Get personalized M&M’s',
		isExternal: true,
		href: 'https://www.anrdoezrs.net/click-100182648-13831227?url=https%3A%2F%2Fwww.mms.com%2Fen-us%2Fideas-and-inspiration%2Fbirthday',
	},
	{
		path: '/assets/images/marketplace/gifts/vidday-gift-shop-graphic-unique-gifts-gift-baskets',
		logo: '/assets/images/marketplace/gifts/logos/vidday-gift-shop-logo-icon-1-800-flowers',
		title: 'Gift Baskets',
		text: 'Get hand-crafted gift baskets',
		isExternal: true,
		href: 'http://1800flowers.7ymy.net/oeoRdo',
	},
]
