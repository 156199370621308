export const experiences = [
	// {
	// 	path: '/assets/images/marketplace/experiences/vidday-gift-shop-graphic-experience-gifts-adventure-challenge',
	// 	logo: '/assets/images/marketplace/experiences/logos/vidday-gift-shop-logo-icon-the-adventure-challenge',
	// 	title: 'Scratch-Off Adventures',
	// 	text: 'Gift mystery experiences',
	// 	isExternal: true,
	// 	href: 'https://www.theadventurechallenge.com/?aff=2133',
	// },
	{
		path: '/assets/images/marketplace/experiences/vidday-gift-shop-graphic-experience-gifts-tinggly',
		logo: '/assets/images/marketplace/experiences/logos/vidday-gift-shop-logo-icon-tinggly',
		title: 'Experience Gift Boxes',
		text: 'Gift worldwide experiences',
		isExternal: true,
		href: 'https://shareasale.com/r.cfm?b=1673282&u=3124742&m=103831&urllink=&afftrack=',
	},
	{
		path: '/assets/images/marketplace/experiences/vidday-gift-shop-graphic-experience-gifts-virgin-experience',
		logo: '/assets/images/marketplace/experiences/logos/vidday-gift-shop-logo-icon-virgin-experience-gifts',
		title: 'Experience Gifts 🇺🇸',
		text: 'Gift unique US experiences',
		isExternal: true,
		href: 'https://virgin-experience-gifts.pxf.io/ZdM5Pq',
	},
	// {
	// 	path: '/assets/images/marketplace/experiences/vidday-gift-shop-graphic-experience-gifts-adrenaline',
	// 	logo: '/assets/images/marketplace/experiences/logos/vidday-gift-shop-logo-icon-adrenaline-experiences',
	// 	title: 'Experience Gifts 🇦🇺',
	// 	text: 'Gift exciting AU adventures',
	// 	isExternal: true,
	// 	href: 'https://www.adrenaline.com/',
	// },
]
