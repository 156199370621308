import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import FooterBase from '../../molecules/FooterBase'
import { useEventContext } from '../../../contexts/EventProvider'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import CreatorView from './CreatorView'
import ContributorView from './ContributorView'
// Needed for resizing parent when this page is used through an iframe  http://davidjbradshaw.github.io/iframe-resizer/
import 'iframe-resizer/js/iframeResizer.contentWindow.min.js'

const Marketplace = () => {
	useScrollTopOnMount()
	const { event, isOwner } = useEventContext()

	// Hide chat widget for www.vidday.com
	useEffect(() => {
		if (typeof window !== 'undefined' && window.LiveChatWidget) {
			if (document.referrer && document.referrer != 'https://share.vidday.com') {
				window.LiveChatWidget.call('hide')
			}
		}
	}, [])

	return (
		<>
			<Helmet>
				<title>VidDay - Marketplace</title>
			</Helmet>
			{isOwner && event ? (
				<>
					<CreatorView />
					<FooterBase />
				</>
			) : (
				<ContributorView />
			)}
		</>
	)
}

export default Marketplace
