export const photofunia = [
	{
		path: '/assets/images/marketplace/photofunia/vidday-video-gift-add-ons-graphic-get-creative-newspaper',
		title: 'Newspaper Effects',
		text: 'Feature photos in the newspaper',
		isExternal: true,
		href: 'https://photofunia.com/search?q=news',
	},
	{
		path: '/assets/images/marketplace/photofunia/vidday-video-gift-add-ons-graphic-get-creative-billboard',
		title: 'Billboard Effects',
		text: 'Display photos on giant billboards',
		isExternal: true,
		href: 'https://photofunia.com/categories/billboards',
	},
	{
		path: '/assets/images/marketplace/photofunia/vidday-video-gift-add-ons-graphic-get-creative-text',
		title: 'Text Effects',
		text: 'Customize text in unique scenes',
		isExternal: true,
		href: 'https://photofunia.com/search?q=your+text',
	},
	{
		path: '/assets/images/marketplace/photofunia/vidday-video-gift-add-ons-graphic-get-creative-magazine',
		title: 'Magazine Effects',
		text: 'Feature photos in magazines',
		isExternal: true,
		href: 'https://photofunia.com/search?q=magazine',
	},
	{
		path: '/assets/images/marketplace/photofunia/vidday-video-gift-add-ons-graphic-get-creative-drawing',
		title: 'Drawing Effects',
		text: 'Turn photos into artistic masterpieces',
		isExternal: true,
		href: 'https://photofunia.com/categories/drawings',
	},
	{
		path: '/assets/images/marketplace/photofunia/vidday-video-gift-add-ons-graphic-get-creative-museum',
		title: 'Museum Effects',
		text: 'Showcase photos on gallery walls',
		isExternal: true,
		href: 'https://photofunia.com/search?q=museum',
	},
]
