/* eslint-disable react/prop-types */
import React, { memo } from 'react'
import { Container, Flex, Image } from '@chakra-ui/react'
import useWindowSize from '../../../../hooks/useWindowSize'
import useImageGenerator from '../../../../hooks/useImageGenerator'
import NavBar from '../../../atoms/NavBar'
import BackButton from '../../../atoms/nav-controls/BackButton'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

/**
 * Hero Header Image and Text
 * @param {string} path The path to the jpg image, example: "/assets/images/gift-card/gift-card-header". It will get appended with "-mobile" "-desktop" "-wide" "-extra-wide"
 * @param {string} path The heading text that will get overlayed
 */
const HeroHeaderNav = ({ path, isGiftShop, extension, children }) => {
	const { width } = useWindowSize()
	const navigate = useNavigate()
	const { uuid } = useParams()
	const location = useLocation()

	let backPath = `${location.pathname.replace('/add-ons', '')}`

	let imgPath = null
	if (width <= 428) {
		imgPath = `${path}-mobile`
	} else {
		imgPath = `${path}-desktop`
	}

	const img = useImageGenerator({
		path: imgPath,
		ext: extension,
	})

	const baseStyles = {
		top: 0,
		left: 0,
		width: '100%',
		minHeight: isGiftShop ? ['328px', '328px', '328px', '328px'] : ['280px', '280px', '224px', '224px'], // 224 + 104
		position: 'absolute',
		zIndex: 2147483638, // over menu, under chat
		// height: '224px',
		bg: 'var(--vidday-colors-link)',
		flexDirection: ['column', 'row'],
		justifyContent: ['center', 'space-evenly'],
		overflow: 'hidden',
		paddingTop: isGiftShop ? '104px' : 0, // Accomodate for the top header on the vidday home
	}

	let backgroundImage = {
		position: 'absolute',
		bottom: ['-10px', '0'],
		width: ['full', 'auto'],
		height: ['auto', 'full'],
		right: ['inherit', 'inherit', '-255px', '-180px', 0],
	}

	if (isGiftShop) {
		backgroundImage = {
			...backgroundImage,
			...{
				width: ['full', '418px'],
				height: ['auto', '224px'],
				right: ['inherit', '-3%', '10%'],
			},
		}
	}

	if (img) {
		return (
			<Flex sx={baseStyles}>
				<Flex
					zIndex="1"
					align={['inherit', 'center']}
					justify={['center', 'start']}
					w="full"
					marginTop={['-40px', 'inherit']}>
					<Container maxW="1024px" textAlign={['center', 'left']}>
						{children}
					</Container>
				</Flex>
				{uuid && (
					<NavBar variant="marketplace">
						<Flex>
							<BackButton onClick={() => navigate(backPath)} bg="whiteAlpha.200" color="white" />
						</Flex>
					</NavBar>
				)}
				<Image sx={backgroundImage} src={img.src} srcSet={img.srcset} />
			</Flex>
		)
	}
	return <Flex sx={baseStyles}>{children}</Flex>
}

// HeroHeaderNav.displayName = 'HeroHeaderNav'

export default HeroHeaderNav
