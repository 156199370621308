export const importable = [
	{
		logo: '/assets/images/marketplace/importable/logos/vidday-video-gift-add-ons-logo-icon-instagram',
		title: 'Instagram',
		href: '/{event}/{uuid}/upload',
	},
	{
		logo: '/assets/images/marketplace/importable/logos/vidday-video-gift-add-ons-logo-icon-facebook',
		title: 'Facebook',
		href: '/{event}/{uuid}/upload',
	},
	{
		logo: '/assets/images/marketplace/importable/logos/vidday-video-gift-add-ons-logo-icon-dropbox',
		title: 'Dropbox',
		href: '/{event}/{uuid}/upload',
	},
	{
		logo: '/assets/images/marketplace/importable/logos/vidday-video-gift-add-ons-logo-icon-upload',
		title: 'Upload',
		href: '/{event}/{uuid}/upload',
	},
	{
		logo: '/assets/images/marketplace/importable/logos/vidday-video-gift-add-ons-logo-icon-camera',
		title: 'Record',
		href: '/{event}/{uuid}/upload',
	},
	{
		logo: '/assets/images/marketplace/importable/logos/vidday-video-gift-add-ons-logo-icon-audio',
		title: 'Audio',
		href: '/{event}/{uuid}/upload',
	},
]
