export const libraries = [
	{
		path: '/assets/images/marketplace/libraries/vidday-video-gift-add-ons-graphic-media-libraries-giphy-desktop',
		logo: '/assets/images/marketplace/libraries/logos/vidday-video-gift-add-ons-logo-icon-giphy',
		title: 'GIFs',
		text: 'Access the best GIFs and animated stickers',
		href: '/{event}/{uuid}/upload-gif',
		hrefFallback: 'https://tenor.com/',
		variant: 'half',
	},
	{
		path: '/assets/images/marketplace/libraries/vidday-video-gift-add-ons-graphic-media-libraries-unsplash-desktop',
		logo: '/assets/images/marketplace/libraries/logos/vidday-video-gift-add-ons-logo-icon-unsplash',
		title: 'Unsplash',
		text: 'Access 3+ million high-resolution images',
		href: '/{event}/{uuid}/upload-gallery',
		hrefFallback: 'https://unsplash.com/',
		variant: 'half',
	},
]
