import React from 'react'
import { chakra, Box, Heading, Image, Text } from '@chakra-ui/react'
import useImageGenerator from '../../../../hooks/useImageGenerator'

const ContentCTA = () => {
	const img = useImageGenerator({
		path: '/assets/images/marketplace/footer/vidday-video-gift-add-ons-footer-emoji-star-struck',
		ext: 'png',
	})
	return (
		<Box textAlign="center" maxW="720px" m="auto" p="1rem">
			<Image m="1rem auto" src={img.src} srcSet={img.srcset} />
			<Heading size="md" mb="1rem">
				Interested in creating content?
			</Heading>
			<Text>
				As our marketplace grows, we’ll be on the hunt for talented individuals interested in creating unique
				content for VidDay users to explore and discover. Send us an email to stay in the loop:{' '}
				<chakra.strong>
					<chakra.a href="mailto:content@vidday.com">content@vidday.com</chakra.a>
				</chakra.strong>
			</Text>
		</Box>
	)
}
export default ContentCTA
