export const posters = [
	{
		path: '/assets/images/marketplace/posters/vidday-gift-shop-graphic-custom-maps-posters-street-map',
		title: 'Street Map Poster',
		text: 'Turn important places into prints',
		isExternal: true,
		href: 'https://imp.i127447.net/rnmoxQ',
	},
	{
		path: '/assets/images/marketplace/posters/vidday-gift-shop-graphic-custom-maps-posters-star-map',
		title: 'Star Map Poster',
		text: 'Create unique prints of the night sky',
		isExternal: true,
		href: 'https://imp.i127447.net/P0MNZz',
	},
	{
		path: '/assets/images/marketplace/posters/vidday-gift-shop-graphic-custom-maps-posters-line-art-poster',
		title: 'Line Art Poster',
		text: 'Design your own line art prints',
		isExternal: true,
		href: 'https://imp.i127447.net/kjrGXz',
	},
	{
		path: '/assets/images/marketplace/posters/vidday-gift-shop-graphic-custom-maps-posters-zodiac-map',
		title: 'Zodiac Poster',
		text: 'Create personalized star sign prints',
		isExternal: true,
		href: 'https://imp.i127447.net/a1MQkW',
	},
	{
		path: '/assets/images/marketplace/posters/vidday-gift-shop-graphic-custom-maps-posters-text-art-poster',
		title: 'Text Art Poster',
		text: 'Turn your images and text into art',
		isExternal: true,
		href: 'https://imp.i127447.net/15vQ3R',
	},
]
